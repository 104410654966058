import React, { MouseEventHandler, useCallback, useEffect } from 'react';
import { Branding as BrandingProps } from '@src/interfaces';
import { trackEvent } from '@src/utils/et';
import ImageComponent from '@src/components/Image/ImageComponent';
import { useInView } from 'react-intersection-observer';
import {Models} from "@data/et-web-api";
import styles from './Branding.module.scss';

const Branding = (branding: BrandingProps) => {
  const { image, link, name} = branding;

  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      const eventName: Models.SponsorLogoImpression['eventName'] = 'Sponsor Logo Impression';
      const eventPayload: Models.SponsorLogoImpression['eventPayload'] = {
        targetUrl: `${link}`,
        adPartner: name
      }
      trackEvent(eventName, eventPayload);
    }
  }, [inView, link, name]);

  const imageComponent = (
    <ImageComponent image={image} showCredits={false} size="large_uncropped" />
  );

  const onClickBranding: MouseEventHandler<HTMLElement> = useCallback(() => {
    const eventName: Models.SponsorLogoClick['eventName'] = 'Sponsor Logo Click';
    const eventPayload: Models.SponsorLogoClick['eventPayload'] = {
      targetUrl: `${link}`,
      adPartner: name
    }
    trackEvent(eventName, eventPayload);
  }, [link, name]);

  const brandingImage = link ? (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a href={link} onClick={onClickBranding} rel="noopener" target="_blank">
      {imageComponent}
    </a>
  ) : (
    imageComponent
  );

  const logoStyle = {
    height: 30,
    width: Math.floor((30 * (image?.width as number)) / (image?.height as number)),
  };

  return (
    <div ref={ref} className={styles.branding}>
      <span className={styles.sponsoredBy}>sponsored by</span>

      <div style={logoStyle}>{brandingImage}</div>
    </div>
  );
};

export default Branding;
