import {Models} from "@data/et-web-api";
import {trackEvent} from "@src/utils/et";
import { Teaser } from '@src/interfaces';
import stripUrlPlaceholder from "@src/utils/strip-url-placeholder";

const onClickVideoTeaser = (teaser: Teaser, title: string|null|undefined, teasersCount: number|undefined, position: number|undefined) => {
  const eventName: Models.PlayContentClick['eventName'] = 'Play Content Click';
  const eventPayload: Models.PlayContentClick['eventPayload'] = {
    targetScreenName: teaser?.screenName || 'Article Detail',
    targetFormatTitle: teaser?.format || '',
    targetFormatId: teaser?.formatId || '',
    targetContentTitle: teaser?.title,
    targetContentId: teaser?.id,
    targetChannelName: teaser?.channel || '',
    laneName: title || 'Not available',
    lanePosition: teaser?.lanePosition || '',
    teaserName: teaser?.title,
    teaserPosition: position?.toString(),
    numberOfTeasers: teasersCount?.toString(),
    targetUrl: stripUrlPlaceholder(teaser?.url),
    isResumeWatching: false,
  }
  trackEvent(eventName, eventPayload);
}

export default onClickVideoTeaser;
