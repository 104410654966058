import type { FC } from 'react';
import type { ContentMeta } from '@src/interfaces';
import type { NewsArticle, WithContext } from 'schema-dts';
import React from 'react';
import Head from 'next/head';
import { jsonLdScriptProps } from 'react-schemaorg';

type MetaTagsProps = ContentMeta;

const MetaTags: FC<MetaTagsProps> = ({
  jsonLD,
  linkTags,
  metaTags,
}) => (
  <Head>
    {jsonLD.map((tag, index) => (
      <script
        key={`jsonld-${index}`}
        {...jsonLdScriptProps<NewsArticle>(tag as WithContext<NewsArticle>)}
      />
    ))}

    {linkTags.map((tag, index) => (
      <link
        key={`link-${index}`}
        href={tag.href}
        rel={tag.rel}
      />
    ))}

    {metaTags.map((tag, index) => (
      <meta
        key={`meta-${index}`}
        content={tag.content}
        {...(tag.name ? { name: tag.name } : {})}
        {...(tag.property ? { property: tag.property } : {})}
      />
    ))}
  </Head>
);

export default MetaTags;
