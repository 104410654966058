import React, { MouseEventHandler, ReactNode } from 'react';
import { LinkProps } from 'next/link';
import { MenuTree } from '@src/interfaces';
import AnyLink from '@src/components/Navigation/AnyLink';
import { trackEvent } from '@src/utils/et';
import {Models} from "@data/et-web-api";

export type NavigationProps = {
  menu: MenuTree;
  group: string;
  renderChildren?: boolean;
  firstLevelHeadline?: boolean;
  children?: ReactNode;
  highlightOnly?: boolean;
} & Pick<LinkProps, 'scroll'>;

const toNavItems = ({
  menu,
  group,
  renderChildren = true,
  firstLevelHeadline = false,
  highlightOnly = false,
  scroll = true,
}: Pick<
  NavigationProps,
  'menu' | 'group' | 'renderChildren' | 'firstLevelHeadline' | 'highlightOnly' | 'scroll'
>) => {
  const onClickNavigation: MouseEventHandler<HTMLElement> = e => {

    const eventName: Models.TabClick['eventName'] = 'Tab Click';
    const eventPayload: Models.TabClick['eventPayload'] = {
      tabLabel: e.currentTarget.title,
    }
    trackEvent(eventName, eventPayload);
  };
  return menu.links
    .filter(({ highlight }) => (highlightOnly ? highlight : true))
    .map(({ link, title, children, highlight = false }) => {
      const childMenuItems =
        renderChildren && children?.length ? (
          <ul>{toNavItems({ menu: { links: children }, group })}</ul>
        ) : (
          ''
        );

      return (
        <li key={title}>
          {firstLevelHeadline ? (
            <h4>{title}</h4>
          ) : (
            <AnyLink
              key={link}
              highlight={highlight}
              link={link}
              onClick={onClickNavigation}
              scroll={scroll}
              title={title}
            >
              {title}
            </AnyLink>
          )}

          {childMenuItems}
        </li>
      );
  });
};

const Navigation = ({
  menu,
  group,
  renderChildren,
  firstLevelHeadline,
  children,
  highlightOnly,
}: NavigationProps) => (
  <nav className="navigation">
    <ul>
      {toNavItems({
        menu,
        group,
        renderChildren,
        firstLevelHeadline,
        highlightOnly,
      })}

      {children}
    </ul>
  </nav>
);

export default Navigation;
