import React, { Dispatch } from 'react';
import dynamic from 'next/dynamic';
import useEventListener from '@use-it/event-listener';

const SurveyDialog = dynamic(() => import('@src/components/survey/SurveyDialog'));

type LazySurveyDialogDetail = {
  text: string;
  url: string;
};

type LazySurveyDialogState = {
  detail?: LazySurveyDialogDetail;
  open: boolean;
};

const LazySurveyDialog = () => {
  const [state, setState] = React.useState<LazySurveyDialogState>({
    open: false,
  });

  const showSurveyDialog = (e: CustomEvent<LazySurveyDialogDetail>) => {
    setState({
      open: true,
      detail: e.detail,
    });
  };

  useEventListener('surveyDialog:show', showSurveyDialog as EventListener);

  const setOpen: Dispatch<boolean> = open =>
    setState(prevState => ({
      detail: prevState.detail,
      open,
    }));

  if (!state.detail) {
    return null;
  }

  return <SurveyDialog detail={state.detail} open={state.open} setOpen={setOpen} />;
};

export default LazySurveyDialog;
