import type { Region} from '@src/interfaces';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import DynamicComponent from '@src/components/DynamicComponent';
import ComponentError from '@src/components/ComponentError';
import Countdown from '@src/components/Countdown/Countdown';

type RegionWithWidth = Region & {
  width: string;
};

const RegionComponent = (region: RegionWithWidth) => {
  const components = region.components.map((component, componentIndex) => {
    const instance = <DynamicComponent component={component} regionWidth={region.width} />

    return (
      <ErrorBoundary
        key={`e${componentIndex}-${component.type}`}
        FallbackComponent={ComponentError}
      >
        {component.schedule
          ? <Countdown schedule={component.schedule}>{instance}</Countdown>
          : instance
        }
      </ErrorBoundary>
    );
  });

  return React.createElement(
    region.tagName ?? 'div',
    {
      className: `region region-index-${region.position - 1} col-12 col-lg-${region.width}`,
    },
    components,
  );
};

export default RegionComponent
