import dayjs from 'dayjs';
import 'dayjs/locale/de';

dayjs.locale('de');

const getFormattedDate = (timestamp: number) => {
  const dateParsed = dayjs.unix(timestamp);
  const dateNow = dayjs();

  // if timestamp is younger than 1 hour
  if (dateNow.diff(dateParsed, 'minute') < 60) {
    if (dateNow.diff(dateParsed, 'minute') === 0) {
      return 'Jetzt';
    }
    if (dateNow.diff(dateParsed, 'minute') === 1) {
      return 'Vor 1 Minute';
    }
    if (dateNow.diff(dateParsed, 'minute') > 1) {
      return `Vor ${dateNow.diff(dateParsed, 'minute')} Minuten`;
    }
  }

  // if timestamp is on the same day
  if (dateNow.diff(dateParsed, 'day') === 0 && dateNow.get('day') === dateParsed.get('day')) {
    return `Heute, ${dateParsed.format('HH:mm')}`;
  }

  return dateParsed.format('DD. MMM YYYY');
};

export default getFormattedDate;
