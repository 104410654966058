import getConfig from 'next/config';
import { loadScript } from '@src/utils/scripts';

type CleverPushEvent =
  | 'initialized'
  | 'subscribed'
  | 'unsubscribed'
  | 'bellReady'
  | 'optInShown'
  | 'panelShown'
  | 'topicsSet'
  | 'optInClosed';

// @see https://developers.cleverpush.com/docs/sdks/javascript/methods#initialization-event
export interface CleverPushSDK {
  config: {
    channelTags: {
      _id: string;
      name: string;
    }[];
    channelTopics: {
      _id: string;
      name: string;
    }[];
  };
  subscriptionManager: {
    setTopics: (topics: string[]) => Promise<void>;
    topics: string[];
  };
  initialized: boolean;
  initTagButtons: () => void;
  initTopicButtons: () => void;
  initWidgets: () => void;
  on: (event: CleverPushEvent, param2: (...args: unknown[]) => void) => void;
  off: (event: CleverPushEvent, param2: (...args: unknown[]) => void) => void;
  // eslint-disable-next-line
  push: (param: unknown[]) => void;
  triggerBellClick: () => void;
}

declare global {
  interface Window {
    CleverPush: CleverPushSDK;
    cleverPushInitCallback?: (err: string) => void;
  }
}

let globalCleverpush: Promise<CleverPushSDK | undefined>;

const getGlobalCleverpush = () => {
  if (!globalCleverpush) {
    const { publicRuntimeConfig } = getConfig();
    if (publicRuntimeConfig.CLEVERPUSH_CHANNEL_ID) {
      globalCleverpush = new Promise<CleverPushSDK>((resolve, reject) => {
        window.cleverPushInitCallback = err => {
          if (err) {
            console.error('Init callback error:', err);
            reject();
          } else {
            resolve(window.CleverPush);
          }
        };
      });
      loadScript<void>(
        `https://static.cleverpush.com/channel/loader/${publicRuntimeConfig.CLEVERPUSH_CHANNEL_ID}.js`,
        undefined,
      ).then(() => console.debug('CleverPush SDK loaded'));
    } else {
      globalCleverpush = Promise.resolve(undefined);
    }
  }
  return globalCleverpush;
};

export default getGlobalCleverpush;
