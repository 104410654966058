import fetchJson from '@src/utils/api/fetch';
import { ContentResource } from '@p7s1/oasis-types';

export type ContentResourceWithUrl = ContentResource & {url?: string}

const fetchRecommendations = (
  cumulusId: string
): Promise<ContentResourceWithUrl[]> => {
  if (!cumulusId) {
    throw new Error('Not enough parameters');
  }
  const url = `/api/cumulus/recommendations?cumulus_id=${cumulusId}`;
  return fetchJson<ContentResourceWithUrl[]>({ url }).then(response => response.body)
    .catch(err => {
      console.error('fetchRecommendations', err);
      return [];
  });
};

export default fetchRecommendations;
