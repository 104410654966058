import getConfig from 'next/config';

export function doSignIn(): void {
  const { publicRuntimeConfig } = getConfig();
  const miraUrl = publicRuntimeConfig.MIRA_URL;
  const miraKey = publicRuntimeConfig.MIRA_KEY;

  if (!miraKey || !miraUrl) {
    throw new Error('missing MIRA config');
  }

  const url = new URL('/sso/auth', miraUrl);
  url.searchParams.set('cid', miraKey);
  url.searchParams.set('callbackUrl', `${window.location.href}?auth=login`);

  window.location.href = url.toString();
}

export function doSignOut(): void {
  const { publicRuntimeConfig } = getConfig();
  const miraUrl = publicRuntimeConfig.MIRA_URL;

  const url = new URL('/sso/user/logout', miraUrl);
  url.searchParams.set('callbackUrl', window.location.href);

  window.location.href = url.toString();
}
