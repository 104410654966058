import type { FC } from 'react';
import type { Teaser } from '@src/interfaces';
import type { TeaserSize } from '@src/components/Teaser/Teaser';
import React, { useEffect, useRef, useCallback, MouseEventHandler } from 'react';
import Link from 'next/link';
import stripUrlPlaceholder from '@src/utils/strip-url-placeholder';
import SvgImage from '@src/components/SvgImage';
import CustomImage from '@src/components/CustomImage';
import clamp from 'clamp-js';
import styles from './styles.module.scss';

type ContentTeaserProps = Teaser & {
  contentBox?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  size?: TeaserSize;
}

const ContentTeaser: FC<ContentTeaserProps> = ({
  title,
  url,
  teaserText,
  image,
  contentBox,
  onClick,
  size= 'large'
}) => {
  const imageRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const paragraphRef = useRef<HTMLParagraphElement>(null);

  const vrContentbox = contentBox ? {
    'data-vr-contentbox': contentBox,
    'data-vr-contentbox-url': url,
  } : {};
  const vrHeadline = contentBox ? { 'data-vr-headline': '' } : {};

  const getContentHeightDiff = () => {
    if (contentRef.current && imageRef.current) {
      return contentRef.current.offsetHeight - imageRef.current.offsetHeight;
    }
    return 0;
  };

  const clampContent = useCallback(() => {
    const heightDiff = getContentHeightDiff();

    if (heightDiff > 0 && paragraphRef.current) {
      clamp(paragraphRef.current, {
        clamp: `${paragraphRef.current.offsetHeight - heightDiff}px`,
        useNativeClamp: false,
        truncationChar: '&#8203;', // Zero-width space
        truncationHTML: '... <span>weiter lesen</span>',
      });
    }
  }, []);

  useEffect(() => {
    if (window.matchMedia('(min-width: 61rem)').matches) {
      clampContent();
    }
  }, [clampContent]);

  return (
    <div className={styles.container}>
      <div ref={imageRef} className={styles.illustration}>
        {image ? <CustomImage {...image} size={size} /> : null}
      </div>

      <div ref={contentRef} className={styles.content}>
        <h3
          className={styles.title}
          {...vrHeadline}
        >
          {title}
        </h3>
        <p ref={paragraphRef} className={styles.paragraph}>{teaserText}</p>

        {url ? <Link as={stripUrlPlaceholder(url)} href="/">
          <a
            aria-label="Zum Artikel"
            className={styles.anchor}
            onClick={onClick}
            {...vrContentbox}
            >
            <div className={styles.anchorContent}>
              <SvgImage ariaHidden reference="chevron-right" />
              Zum Artikel
            </div>
          </a>
        </Link> : null}
      </div>
    </div>
  );
};

export default ContentTeaser;
