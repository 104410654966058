import {CoralContext} from "@src/interfaces";

declare global {
  interface Window {
    IOMm?: (action: string, object: {
      cp: string
    }) => void;
  }
}

const trackPageImpression = (context: CoralContext) => {
  if (!context) {
    console.warn('No context');
    return;
  }
  const { marketingCategory } = context;
  if (!marketingCategory) {
    return;
  }
  window.IOMm?.('pageview', {cp: marketingCategory});
};

export default trackPageImpression;
