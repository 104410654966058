import { Teaser } from '@src/interfaces';
import fetchJson from '@src/utils/api/fetch';

const fetchTeasers = (
  collection: string,
  offset: number,
  length: number,
): Promise<Teaser[]> => {
  const params = new URLSearchParams();
  params.set('offset', offset.toString());
  params.set('length', length.toString());
  const url = `/api/headless/p7s1p4/collection/${collection}?${params.toString()}`;
  return fetchJson<Teaser[]>({ url }).then(response => response.body)
    .catch(err => {
      console.error('fetchTeasers', err);
      return [];
  });
};

export default fetchTeasers;
