import { useRouter } from 'next/router';
import stripUrlPlaceholder from '@src/utils/strip-url-placeholder';
import Link, { LinkProps } from 'next/link';
import React, { MouseEventHandler, PropsWithChildren } from 'react';

type AnyLinkProps = {
  link: string;
  title: string;
  highlight?: boolean | null;
  onClick?: MouseEventHandler<HTMLElement>;
} & Pick<LinkProps, 'scroll'>;

const AnyLink = ({
  children,
  link,
  title,
  highlight = false,
  scroll,
  onClick,
}: PropsWithChildren<AnyLinkProps>) => {
  const router = useRouter();
  const uri = stripUrlPlaceholder(link);

  const className = `${highlight ? 'is-highlight' : ''}${router.asPath === uri ? ' active' : ''}`;

  const isExternal = /^https?:\/\//i.test(uri);

  return isExternal ? (
    <a
      className={className}
      href={uri}
      onClick={onClick}
      rel="noopener"
      target="_blank"
      title={title}
    >
      {children}
    </a>
  ) : (
    <Link as={uri} href="/" scroll={scroll}>
      <a className={className} onClick={onClick} title={title}>
        {children}
      </a>
    </Link>
  );
};

export default AnyLink;
