import type { FC } from 'react';
import type { Image as ImageModel } from '@src/interfaces';
import type { ImageLoader } from 'next/image';
import React from 'react';
import Image from 'next/image';
import getConfig from 'next/config';
import { ImageSize } from '@src/components/Image/ImageComponent';

type CustomImageProps = ImageModel & {
  ariaHidden?: boolean;
  className?: string;
  preload?: boolean;
  size: ImageSize;
};

const CustomImage: FC<CustomImageProps> = ({
  url,
  alt,
  ariaHidden,
  className,
  preload,
  size,
}) => {
  const SIZE_WIDTH: { [K in ImageSize]: number } = {
    'xsmall': 128,
    'xsmall_square': 128,
    'small': 227,
    'medium': 536,
    'large': 768,
    'large_uncropped': 828,
    'xlarge': 1377,
  };

  const imgWidth: number = SIZE_WIDTH[size];
  const isSquare = size.endsWith('_square')
  const heightMultiplier: number = isSquare ? 1 : 0.5625;
  const customLoader: ImageLoader = ({ src, width }) => {
    const { publicRuntimeConfig } = getConfig();
    let style: string;
    switch (size) {
      case 'large_uncropped':
        style = `/hera_dyn_${width}w_uncropped/`;
        break;
      case 'xsmall_square':
        style = `/hera_dyn_${width}w_square/`;
        break;

      default:
        style = `/hera_dyn_${width}w_${Math.round(heightMultiplier * width)}h/`;
    }

    return `${publicRuntimeConfig.PUBLIC_IMAGE_HOST}${src}`.replace('/large/', style);
  }

  let sizes: string;
  switch (size) {
    case "medium":
      sizes = "536px";
      break;
    case "small":
      sizes = "227px";
      break;
    case "xsmall":
    case "xsmall_square":
      sizes = "128px";
      break;
    case "large":
      sizes = "768px";
      break;
    default:
      // default value from react image
      sizes = "100vw";
  }

  return (
    <Image
      alt={alt}
      aria-hidden={Boolean(ariaHidden)}
      className={className || ''}
      height={heightMultiplier * imgWidth}
      layout='responsive'
      loader={customLoader}
      objectFit="cover"
      priority={preload}
      sizes={sizes}
      src={url}
      width={imgWidth}
    />
  );
};

export default CustomImage;
