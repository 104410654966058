import React from 'react';
import SocialIcons from '@src/components/SocialButtons/SocialIcons';

const LINK_URLS = {
  facebook: 'https://www.facebook.com/puls24news',
  instagram: 'https://www.instagram.com/puls24news/',
  twitter: 'https://twitter.com/puls24news',
  tiktok: 'https://www.tiktok.com/@puls24news',
  bluesky: 'https://bsky.app/profile/puls24.bsky.social',
};

const FollowUs = () => (
  <div aria-label="Soziale Medien Links" className="social-button-group" role="group">
    <SocialIcons baseUrls={LINK_URLS} />
  </div>
);

export default FollowUs;
