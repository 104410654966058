import type { FC } from 'react';
import React, { useContext } from 'react';
import { AdsContext } from '@src/context/ads';
import Somtag, { SomtagProps } from '@src/components/Ad/Somtag';

type SkyscraperProps = {
  id: string;
};

const Skyscraper: FC<SkyscraperProps> = ({ id }) => {
  const { somtagInitialized } = useContext(AdsContext);

  const component: SomtagProps = {
    id,
    slot: 'skyscraper1',
    display: ['desktop', 'mobile'],
    type: 'somtag',
    className: 'ad-component-skyscraper',
    reload: 30000,
  };
  return (
    <div className="sticky-sky-wrapper">
      {somtagInitialized ? <Somtag {...component} /> : null}
    </div>
  );
};
export default Skyscraper;
