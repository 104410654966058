import React, { MouseEventHandler, useCallback, useEffect } from 'react';
import { BannerComponent } from '@src/interfaces';
import ImageComponent from '@src/components/Image/ImageComponent';
import AnyLink from '@src/components/Navigation/AnyLink';
import { trackEvent } from '@src/utils/et';
import { useInView } from 'react-intersection-observer';
import BrandedTitle from '@src/components/Branding/BrandedTitle';
import classNames from 'classnames';
import {Models} from "@data/et-web-api";
import styles from './Banner.module.scss';

const Banner = (props: BannerComponent) => {
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      const eventName: Models.BannerImpression['eventName'] = 'Banner Impression';
      const eventPayload: Models.BannerImpression['eventPayload'] = {
        bannerName: `${props.name}`,
      }
      trackEvent(eventName, eventPayload);
    }
  }, [inView, props.name]);

  const onClickBanner: MouseEventHandler<HTMLElement> = useCallback(() => {
    const eventName: Models.BannerClick['eventName'] = 'Banner Click';
    const eventPayload: Models.BannerClick['eventPayload'] = {
      bannerName: `${props.name}`
    }
    trackEvent(eventName, eventPayload);
  }, [props.name]);

  const image = <ImageComponent image={props.image} size="large_uncropped" />;

  const inner = props.link ? (
    <AnyLink link={props.link} onClick={onClickBanner} title={props.title}>
      {image}
    </AnyLink>
  ) : (
    image
  );

  return (
    <div className={styles.bannerContainer}>
      {props.branding ? <BrandedTitle branding={props.branding} /> : null}

      <div ref={ref} className={classNames(styles.bannerComponent, 'banner', 'banner-component')}>
        {inner}
      </div>
    </div>
  );
};

export default Banner;
