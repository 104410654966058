import type { FC, MouseEventHandler} from 'react';
import type { Teaser } from '@src/interfaces';
import React, {useEffect, useRef } from 'react';
import classnames from 'classnames';
import Link from 'next/link';
import stripUrlPlaceholder from '@src/utils/strip-url-placeholder';
import getFormattedDate from '@src/utils/get-formatted-date';
import SvgImage from '@src/components/SvgImage';
import styles from './styles.module.scss';

type LinkTeaserProps = Teaser & {
  contentBox?: string;
  focusOnRender?: boolean;
  fadeIn?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  className?: string;
}

const LinkTeaser: FC<LinkTeaserProps> = ({
                                           title,
                                           url,
                                           contentBox,
                                           advertorial,
                                           updated,
                                           focusOnRender,
                                           onClick,
                                           className = '',
                                         }) => {
  const triggerEl = useRef<HTMLButtonElement>(null);

  const vrContentbox = contentBox ? {
    'data-vr-contentbox': contentBox,
    'data-vr-contentbox-url': url,
  } : {};
  const vrHeadline = contentBox ? { 'data-vr-headline': '' } : {};

  useEffect(() => {
    if (triggerEl.current && focusOnRender) {
      triggerEl.current.focus();
    }
  }, [focusOnRender]);

  return (
    <div className={classnames(styles.container, {
      [className]: className,
    })}
    >
      <Link as={stripUrlPlaceholder(url)} href="/">
        <a
          className={styles.anchor}
          onClick={onClick}
          {...vrContentbox}
        >
          <div className={styles.titleContainer}>
            <h3
              className={styles.title}
              {...vrHeadline}
            >
              {title}
            </h3>

            {advertorial ? <div className={styles.meta}>Anzeige</div> : null}

            {updated ? <div className={styles.meta}>{getFormattedDate(updated)}</div> : null}
          </div>

          <div className={styles.iconContainer}>
            <SvgImage
              className={styles.icon}
              reference='arrow-right-dark'
            />
          </div>
        </a>
      </Link>
    </div>
  );
};

export default LinkTeaser;
