import axios from 'axios';
import { UserToken } from './user-token';

const getUserToken = async ({ miraUrl, cookie }: { miraUrl: string; cookie?: string }) => {
  try {
    const response = await axios.get<{ response: UserToken }>(
      new URL('/sso/user', miraUrl).toString(),
      cookie
        ? {
            headers: {
              cookie,
            },
          }
        : {
            withCredentials: true,
          },
    );
    return response.data.response;
  } catch {
    return undefined;
  }
};

export default getUserToken;
