import React from 'react';
import { Branding as BrandingModel } from '@src/interfaces';
import ComponentTitle from '@src/components/ComponentTitle';
import getClassName from '@src/utils/get-class-name';
import Branding from '@src/components/Branding/Branding';

interface BrandedTitleProps {
  branding?: BrandingModel;
  title?: null | string;
  link?: null | string;
}

const BrandedTitle = ({ branding, link, title }: BrandedTitleProps) => {
  if (!title && !branding) {
    return null;
  }
  return (
    <div
      className={getClassName([
        'branded-title-container',
        [!!branding, '', 'has-branding'],
        [!title, '', 'no-title'],
      ])}
    >
      {title ? <ComponentTitle link={link} title={title} /> : null}

      {branding ? <Branding {...branding} /> : null}
    </div>
  );
};

export default BrandedTitle;
