import { somMarketingDefault } from '@src/utils/somtag';
import { ContentResource } from '@p7s1/oasis-types';

const livestreamResource = {
  id: 'puls24-at-hd',
  title: 'JETZT LIVE IM TV',
  contentType: 'live',
  somMarketing: {
    ...somMarketingDefault,
    ...{
      video: {
        longplay: true,
        blocks: {
          pre: { enabled: true, slots: {} },
          mid: { enabled: true, slots: {} },
          post: { enabled: true, slots: {} },
          overlay: { enabled: true },
          pause: { enabled: true },
        },
      },
      taxonomy: {
        channels: ['live', 'other'],
        content: 'content',
        topics: {},
      },
    },
  },
} as unknown as ContentResource;

export default livestreamResource;
