import type { ResponsiveComponent } from '@src/interfaces';
import React from 'react';
import { TeaserCarouselComponent } from '@src/interfaces';
import TeaserSlider from '@src/components/TeaserSlider/TeaserSlider';

const TeaserCarousel = (props: TeaserCarouselComponent & ResponsiveComponent) => {
  if (!props.teasers.length) return null;

  return (
    <TeaserSlider
      {...props}
      className="teaser-carousel"
      mobileRows={1}
      type="teaserSlider"
    />
  );
};

export default TeaserCarousel;
