import { Models } from '@data/et-web-api';
import { Vendor } from '@src/utils/cmp';

const getEt = () => import('@data/et-web-api');

export const enableWithVendors: (vendors: Vendor[]) => void = vendors =>
  getEt().then(et => {
  et.setup({
    et: {
      mapperUrl: 'https://datalayer.p7s1.io/c49530e52ac0962f84485c37dc9958a9.js'
    }
  });
  et.enableWithVendorList(vendors);
})

export const trackEvent = (eventName: Models.Event['eventName'], eventPayload: Models.Event['eventPayload']) =>
  getEt().then(et => et.trackEvent(eventName, eventPayload));

export const trackPage = (context: Models.Context) =>
  getEt().then(et => et.trackPage(context));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const trackMedia = (eventName: string, eventPayload: any) =>
  getEt().then(et => et.trackMedia(eventName, eventPayload))

export const setContext = (context: Models.Context) =>
  getEt().then(et => et.setContext(context))
