import { UserToken } from '@src/components/auth/user-token';
import { useCallback, useEffect, useState } from 'react';
import getConfig from 'next/config';
import getUserToken from '@src/components/auth/get-user-token';

const useLogin = (userToken?: UserToken) => {
  const { publicRuntimeConfig } = getConfig();
  const miraUrl = publicRuntimeConfig.MIRA_URL;
  const [state, setState] = useState<{
    userToken?: UserToken;
    loading: boolean;
    failed: boolean;
  }>({
    userToken,
    loading: false,
    failed: false,
  });

  const updateUserToken = useCallback(() => {
    setState(currentState => ({
      ...currentState,
      loading: true,
    }));
    getUserToken({ miraUrl })
      .then(token => {
        setState({
          userToken: token,
          loading: false,
          failed: false,
        });
      })
      .catch(() => {
        setState({
          loading: false,
          failed: true,
        });
      });
  }, [miraUrl]);

  useEffect(() => {
    if (
      !state.failed &&
      !state.loading &&
      state.userToken &&
      state.userToken.expiresAt &&
      state.userToken.expiresAt * 1000 > Date.now()
    ) {
      const timeout = setTimeout(updateUserToken, state.userToken.expiresAt * 1000 - Date.now());
      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [state, updateUserToken]);

  useEffect(() => {
    if (
      !state.failed &&
      !state.loading &&
      state.userToken &&
      state.userToken.expiresAt &&
      state.userToken.expiresAt * 1000 <= Date.now()
    ) {
      setState({
        loading: false,
        failed: false,
      });
    }
  }, [state]);

  useEffect(() => {
    updateUserToken();
  }, [updateUserToken]);

  return state;
};

export default useLogin;
