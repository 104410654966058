import { useEffect, useState } from 'react';

const useIsPageVisible = () => {
  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    const listener = () => {
      setIsVisible(document.visibilityState === 'visible');
    };
    document.addEventListener('visibilitychange', listener);
    return () => {
      document.removeEventListener('visibilitychange', listener);
    };
  }, []);
  return isVisible;
};

export default useIsPageVisible;
