import type { FC } from 'react';
import type { PlayerInstance } from '@src/components/OasisPlayer/PlayerConfig';
import React, { useState, useEffect, useContext } from 'react';
import { useRouter } from 'next/router';
import { useMedia } from 'react-use';
import { PlayerEvent } from '@p7s1/oasis-types';
import useIsPageVisible from '@src/hooks/useIsPageVisible';
import {
  initialState,
  OasisPlayerStoreContext,
} from '@src/components/OasisPlayer/OasisPlayerStore';
import RouteContext from '@src/components/Layout/route-context';
import { updatePlayerInstance } from '@src/components/OasisPlayer/PlayerConfig';
import {trackEvent} from '@src/utils/et';
import {Models} from "@data/et-web-api";
import styles from './styles.module.scss';

const StickyPlayer: FC = () => {
  const [adIsPlaying, setAdIsPlaying] = useState(false);
  const [prevState, setPrevState] = useState(initialState);
  const { state, dispatch } = useContext(OasisPlayerStoreContext);
  const route = useContext(RouteContext);
  const isPageVisible = useIsPageVisible();
  const mobile = useMedia('(max-width: 45rem)', true);

  const [playerInstance, setPlayerInstance] = useState<PlayerInstance>({
    playerApi: null,
    videoDomElement: null,
  });
  const router = useRouter();

  // on init
  useEffect(() => {
    const handleRouteChange = () => {
      dispatch({ type: 'update', state: { isInView: false, routeChanged: true}, routeChanged: true });
    };

    if (!state.activePlayer) {
      const initial = route.meta.ioConfig?.page_type === 'article' || mobile;
      dispatch({ type: 'livestream', isInitial: initial });
    }

    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updatePlayerInstance(state, dispatch, prevState, playerInstance)
      .then(currentPlayerInstance => {
        setPlayerInstance(currentPlayerInstance);
        setPrevState(state);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    const playingHandler = () => setAdIsPlaying(false);
    const adStartedHandler = () => setAdIsPlaying(true);

    playerInstance.playerApi?.on(PlayerEvent.AD_STARTED, adStartedHandler);
    playerInstance.playerApi?.on(PlayerEvent.PLAYING, playingHandler);
    return () => {
      playerInstance.playerApi?.off(PlayerEvent.AD_STARTED, adStartedHandler);
      playerInstance.playerApi?.off(PlayerEvent.PLAYING, playingHandler);
    };
  }, [playerInstance.playerApi]);

  useEffect(() => {
    if (playerInstance.playerApi && adIsPlaying) {
      if (!isPageVisible) {
        playerInstance.playerApi?.pause();
      } else {
        playerInstance.playerApi?.play();
      }
    }
  }, [playerInstance.playerApi, isPageVisible, adIsPlaying]);

  useEffect(() => {
    if (!!state.activePlayer && !state.isInView && state.routeChanged && mobile) {
      document.documentElement.setAttribute('data-player-padding', 'true')
    } else {
      document.documentElement.setAttribute('data-player-padding', 'false')
    }
  })

  useEffect(() => {
    const onPlayerClose = () => {
      const eventName: Models.CloseClick['eventName'] = 'Close Click';
      trackEvent(eventName, {});
    };
    playerInstance.playerApi?.on(PlayerEvent.DESTROYED, onPlayerClose);
  }, [playerInstance.playerApi]);

  return (
    <div className={styles.stickyPlayerWrapper} data-active={!!state.activePlayer && !state.isInView}>
      <div className={styles.stickyPlayer}>
        <div id='video-frame-sticky' />
      </div>
    </div>
  );
};

export default StickyPlayer;
