import type { FC } from 'react';
import type { ThemeContextType } from '@src/context/theme';
import React, { useContext } from 'react';
import { ThemeContext } from '@src/context/theme';
import classnames from 'classnames';
import SvgImage from '@src/components/SvgImage';
import styles from './styles.module.scss';

type ThemeToggleProps = {
  className?: string;
  toggle?: boolean;
};

const ThemeToggle: FC<ThemeToggleProps> = ({
  toggle = true,
  className = '',
}) => {
  const { isDarkMode, toggleTheme } = useContext<ThemeContextType>(ThemeContext);

  return (
    <button
      aria-label="Dunkler Modus"
      aria-pressed={!!isDarkMode}
      className={classnames(styles.themeToggle, {
        [styles.isButton]: !toggle,
        [styles.isToggle]: toggle,
        [className]: !!className,
      })}
      onClick={toggleTheme}
      type="button"
    >
      {toggle ? (
        <>
          <SvgImage
            ariaHidden
            className={styles.themeLight}
            reference="sun"
          />
          <SvgImage
            ariaHidden
            className={styles.themeDark}
            reference="moon"
          />
        </>
      ) : (
        <SvgImage
          ariaHidden
          className={isDarkMode ? styles.themeLight : styles.themeDark}
          reference={isDarkMode ? 'sun' : 'moon'}
        />
      )}
    </button>
  );
};

export default ThemeToggle;
