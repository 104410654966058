import React from 'react';
import { ErrorComponent } from '@src/interfaces';

const Error = (props: ErrorComponent) => (
  <div className="error-component">
    <h1 className="component-title">{props.status}</h1>

    {props.message ? <p>{props.message}</p> : null}
  </div>
);

export default Error;
