import React, { SyntheticEvent, useState } from 'react';
import SvgImage from '@src/components/SvgImage';
import copy from 'copy-to-clipboard';

export interface ShareButtonProps {
  className: string;
  url: string;
  label: string;
}
const ShareButton = ({ className, url, label}: ShareButtonProps) => {
  const [shareLabel, setShareLabel] = useState('Link kopieren')

  const copyUrl = (e: SyntheticEvent) => {
    const newUrl = new URL(url, window.location.href);
    e.preventDefault();
    if (navigator.share) {
      navigator.share({
        title: label,
        url: newUrl.href
      })
    } else {
      copy(newUrl.href);
      setShareLabel('Link kopiert')
    }
  };

  return (
    <a aria-label="Teilen" className={className} href="#" onClick={copyUrl} role="button">
      <SvgImage className="social-icon" reference="share" title="share"/> {shareLabel}
    </a>
  )
}

export default ShareButton
