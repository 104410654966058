import React, { createContext, Dispatch, ProviderProps, Reducer, useMemo, useReducer } from 'react';
import supportsLocalStorage from '@src/utils/storage';
import { trackPage } from '@src/utils/et';
import livestreamResource from '@src/components/OasisPlayer/livestream-resource';
import { ContentResource } from '@p7s1/oasis-types';

export type Action =
  | { type: 'reset' }
  | { type: 'livestream'; isInitial: boolean }
  | { type: 'update'; state: Partial<State>; routeChanged: boolean };

export interface State {
  activePlayer: string;
  contentResource?: ContentResource;
  isInView: boolean;
  routeChanged: boolean;
  routeChangesSurvived: number;
}

export const initialState: State = {
  activePlayer: '',
  contentResource: undefined,
  isInView: true,
  routeChanged: false,
  routeChangesSurvived: 0,
};

export const initialContextState: {
  state: State;
  dispatch: Dispatch<Action>;
} = {
  state: initialState,
  dispatch: () => null,
};

const reducer: Reducer<State, Action> = (global, action) => {
  let currentState = global;

  switch (action.type) {
    case 'update':
      currentState = { ...global, ...action.state };
      if (action.routeChanged && currentState.contentResource?.id) {
        currentState.routeChangesSurvived += 1;
      }
      break;
    case 'livestream':
      if (
        action.isInitial &&
        (!supportsLocalStorage() || localStorage.getItem('livestream-initial') !== 'yes')
      ) {
        break;
      }
      currentState = {
        ...global,
        ...{
          activePlayer: 'livestream',
          contentResource: livestreamResource,
          isInView: false,
          routeChangesSurvived: 0,
        },
      };
      trackPage({});
      if (supportsLocalStorage()) {
        localStorage.setItem('livestream-initial', 'yes');
      }
      break;
    case 'reset':
      currentState = { ...initialState, ...{ routeChangesSurvived: 0 } };
      if (supportsLocalStorage()) {
        localStorage.removeItem('livestream-initial');
      }
      break;
    default:
      currentState = global;
  }

  return currentState;
};

export const OasisPlayerStoreContext = createContext<{
  state: State;
  dispatch: Dispatch<Action>;
}>(initialContextState);

const OasisPlayerStore = ({ children }: Pick<ProviderProps<State>, 'children'>) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = useMemo(() => ({ state, dispatch }), [state]);
  return (
    <OasisPlayerStoreContext.Provider value={value}>{children}</OasisPlayerStoreContext.Provider>
  );
};

export default OasisPlayerStore;
