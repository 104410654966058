import React, { MouseEventHandler, useCallback } from 'react';
import classNames from 'classnames';
import { LoadMore as LoadMoreModel } from '@src/interfaces';
import { trackEvent, trackPage } from '@src/utils/et';
import {Models} from "@data/et-web-api";
import styles from './LoadMore.module.scss';

const LoadMore = ({
  enabled,
  label,
  collection,
  loading,
  id,
  title,
  onClickMore,
}: LoadMoreModel & {
  loading: boolean;
  id: string;
  title: string|null|undefined;
  onClickMore?: MouseEventHandler<HTMLElement>;
}) => {
  const clicked = useCallback(
    (e : React.MouseEvent<HTMLButtonElement>) => {
      if (onClickMore) {
        onClickMore(e);
      }
      trackPage({});

      const eventName: Models.ShowMoreClick['eventName'] = 'Show More Click';
      const eventPayload: Models.ShowMoreClick['eventPayload'] = {
          laneName: title || collection || '',
          laneTrackingId: id,
      };
      trackEvent(eventName, eventPayload);
    },
    [collection, onClickMore, id, title],
  );

  if (!enabled) {
    return null;
  }

  return (
    <div className={classNames(styles.loadMore, loading ? styles.isLoading : false)}>
      <button className={classNames(styles.btn, 'btn', 'btn-red')} onClick={clicked} type="button">
        {label}

        <div className={classNames('lds-dual-ring', styles.ldsDualRing)} />
      </button>
    </div>
  );
};

export default LoadMore;
