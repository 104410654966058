import fetchJson from '@src/utils/api/fetch';
import {MinimalTeaser} from "@src/interfaces";

const fetchDiscoveryLinks = async (): Promise<MinimalTeaser[]> => {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line no-underscore-dangle
    return window.__NEXT_DATA__.props.pageProps.discoveryLinks;
  }
  return fetchJson<MinimalTeaser[]>({
    url: `/api/headless/p7s1p4/random_content`,
  })
    .then(response => response.body)
    .catch(err => {
      console.error('fetchDiscoveryLinks', err);
      return [];
  });

};

export default fetchDiscoveryLinks;
