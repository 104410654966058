import type { ResponsiveComponent, Teaser } from '@src/interfaces';
import React, { useState, useEffect } from 'react';
import { useMedia } from 'react-use';
import classnames from 'classnames';
import { TeaserListComponent } from '@src/interfaces';
import TinyTeaser from '@src/components/TinyTeaser';
import LinkTeaser from '@src/components/LinkTeaser';
import ExpandableTeaser from '@src/components/ExpandableTeaser';
import ComponentTitle from '@src/components/ComponentTitle';
import onClickTeaser from "@src/hooks/onClickTeaser";
import onClickVideoTeaser from "@src/hooks/onClickVideoTeaser";
import styles from './styles.module.scss';

const TeaserList = ({
  id,
  teasers,
  title,
  link,
  componentType = 'tinyTeaser',
}: TeaserListComponent & ResponsiveComponent) => {
  const [expandedTeaser, setExpandedTeaser] = useState<Teaser | null>(null);
  const isMobile = useMedia('(max-width: 576px)', true);
  const isAccordion = componentType === 'expandableTeaser' || isMobile && componentType === 'linkTeaser';

  useEffect(() => {
    // The container-query-polyfill has a bug and doesn't always refresh
    // This trigger the refresh by amending an attribute on the element its directly observing
    if (isAccordion && !('container' in document.documentElement.style)) {
      document.documentElement.setAttribute('data-expanded', expandedTeaser?.url || '');
    }
    if (!isAccordion) {
      setExpandedTeaser(null);
    }
  }, [isAccordion, expandedTeaser]);

  if (!teasers.length) return null;

  const closeExpandedTeaser = () => {
    setExpandedTeaser(null);
  };

  return (
    <div className={styles.teaserList} data-vr-zone={`TeaserList ${title ?? id}`}>
      <ComponentTitle link={link} title={title} />

      <div className={styles.content}>
        {expandedTeaser ? <ExpandableTeaser
          key={expandedTeaser.url}
          className={styles.expandedTeaser}
          contentBox="SingleTeaser #1"
          expanded
          fadeIn
          focusOnRender
            // Expanded accordions are always first position...?
          onClick={() => onClickTeaser(expandedTeaser, title, teasers.length, 1)}
          onToggle={closeExpandedTeaser}
          {...expandedTeaser}
          /> : null}

        <ul className={styles.teasers}>
          {teasers.map((teaser, index) => (
            <li
              key={teaser.url}
              className={classnames(
                isAccordion ? styles.expandableTeaserItem : styles.teaserItem,
                {
                  [styles.expandedTeaserItem]: expandedTeaser?.url === teaser.url,
                  [styles.teaserItemWide]: componentType === 'tinyTeaser',
                },
              )}
            >
              {componentType === 'tinyTeaser' && (
                <TinyTeaser contentBox={`TeaserList Box #${index + 1}`} onClick={() => teaser.videoDuration ? onClickVideoTeaser(teaser, title, teasers.length, index+1) : onClickTeaser(teaser, title, teasers.length, index+1)} {...teaser} />
              )}

              {!isMobile && componentType === 'linkTeaser' && (
                <LinkTeaser contentBox={`SingleTeaser #${index + 1}`} onClick={() => onClickTeaser(teaser, title, teasers.length, index+1)} {...teaser} />
              )}

              {isAccordion ? <ExpandableTeaser
                contentBox={`SingleTeaser #${index + 1}`}
                expanded={expandedTeaser?.url === teaser.url}
                onClick={() => onClickTeaser(teaser, title, teasers.length, index+1)}
                onToggle={() => setExpandedTeaser(teaser)}
                {...teaser}
                /> : null}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TeaserList;
