import type { FC } from 'react';
import type { ResponsiveComponent } from '@src/interfaces';
import React, {useEffect, useState} from 'react';
import { Teaser, TeaserGridComponent } from '@src/interfaces';
import TeaserComponent from '@src/components/TeaserComponent';
import ComponentTitle from '@src/components/ComponentTitle';
import LoadMore from '@src/components/LoadMore/LoadMore';
import fetchTeasers from '@src/utils/api/fetch-teasers';
import onClickVideoTeaser from "@src/hooks/onClickVideoTeaser";
import onClickTeaser from "@src/hooks/onClickTeaser";

type TeaserGridProps = TeaserGridComponent & ResponsiveComponent;

const TeaserGrid: FC<TeaserGridProps> = ({
  id,
  teasers: initialTeasers,
  title,
  size,
  regionWidth,
  link,
  loadMore,
}) => {
  const [teasers, setTeasers] = useState<Teaser[]>(initialTeasers);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(loadMore?.offset || 0);
  const [hasMore, setHasMore] = useState<boolean>(true);

  useEffect(() => {
    if (typeof window === 'undefined') {
      // Server-side.
      return;
    }
    // Rerender grid on client-side if page loads with another collection.
    setTeasers([...initialTeasers]);
  }, [initialTeasers]);

  if (!teasers.length) return null;

  const handleTeasersLoaded = (newTeasers: Teaser[]) => {
    setTeasers([...teasers, ...newTeasers]);
    setIsLoading(false);
    setOffset(offset + newTeasers.length);
    setHasMore(newTeasers.length > 0);
  };

  const handleLoadMore = async () => {
    setIsLoading(true);

    fetchTeasers(
      loadMore?.collection as string,
      offset,
      initialTeasers.length,
    ).then(handleTeasersLoaded);
  };

  const getClasseNames = (index: number) => {
    const TEASER_CLASS_DEFINITIONS = {
      'grid-small': 'col-xs-6 col-md-4 col-xl-3',
      'grid-medium': 'col-xs-6 col-lg-4',
      'grid-large': 'col-xs-12 col-md-6',
    };
    const isFullWidth = regionWidth === '12';

    let classNames = TEASER_CLASS_DEFINITIONS['grid-large'];
    if (isFullWidth && size === 'small') {
      classNames = TEASER_CLASS_DEFINITIONS['grid-small'];
    } else if (size === 'small' || isFullWidth && size === 'medium') {
      classNames = TEASER_CLASS_DEFINITIONS['grid-medium'];
    }

    if (teasers.length % 2 === 1 && index === 0) {
      classNames = classNames.replace(/col-xs-\d+/g, 'col-xs-12');
    }

    return classNames;
  }

  return (
    <div className="row" data-vr-zone={`TeaserGrid ${title ?? id}`}>
      <ComponentTitle link={link} title={title} />

      {teasers.map((teaser, index) => (
        <div key={teaser.id} className={getClasseNames(index)}>
          <TeaserComponent
            contentBox={`TeaserGrid Box #${index + 1}`}
            onClick={() => teaser.videoDuration ? onClickVideoTeaser(teaser, title, teasers.length, index+1) : onClickTeaser(teaser, title, teasers.length, index+1)}
            size="medium"
            {...teaser}
          />
        </div>
      ))}

      {loadMore?.collection && hasMore ? <LoadMore {...loadMore} id={id} loading={isLoading} onClickMore={handleLoadMore} title={title} /> : null}
    </div>
  );
};

export default TeaserGrid;
