import { useEffect, useState } from 'react';

const useHasBeenActive = () => {

  const [hasBeenActive, setHasBeenActive] = useState(false);
  useEffect(() => {
    const evts = ['click', 'mousemove', 'focus', 'touchstart'];
    const handleInteraction = (e: Event) => {
      if (e.isTrusted) {
        setHasBeenActive(true)
      }
    };

    if (typeof window !== 'undefined' && !hasBeenActive) {
      evts.forEach(evt => {
        window.addEventListener(evt, handleInteraction, {once: true})
      })

      return () => {
        evts.forEach(evt => {
          window.removeEventListener(evt, handleInteraction)
        })
      }
    }

    return () => null;
  }, [hasBeenActive]);

  return hasBeenActive;
};

export default useHasBeenActive;
