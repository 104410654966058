import React, { MouseEventHandler, useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { doSignIn, doSignOut } from '@src/utils/auth';
import { trackEvent } from '@src/utils/et';
import useLogin from '@src/components/auth/use-login';
import SvgImage from '@src/components/SvgImage';
import {Models} from "@data/et-web-api";

interface MeComponent {
  popupVisible?: boolean;
  className?: string;
}

const Me = ({ popupVisible = false, className }: MeComponent) => {
  const { userToken } = useLogin();
  const [showPopup, setShowPopup] = useState<boolean>(false);

  // if (userToken) {
  //   setContext({miraToken: userToken.miratoken})
  // }

  useEffect(() => {
    if (!popupVisible) {
      setShowPopup(false);
    }
  }, [popupVisible]);

  const onClickSignout: MouseEventHandler<HTMLElement> = e => {
    e.preventDefault();
    doSignOut();
  };

  const popup = userToken ? (
    <ul className={`mira-popup ${showPopup ? 'visible' : 'hidden'}`}>
      <li>
        <span className="user-name">Hallo, {userToken.data?.person?.name}</span>
      </li>

      <li>
        <button className="btn btn-gray" onClick={onClickSignout} title="Logout" type="button">
          Ausloggen
        </button>
      </li>
    </ul>
  ) : null;

  const onClickSignin: MouseEventHandler<HTMLElement> = e => {
    e.preventDefault();
    if (!userToken) {
      doSignIn();

      const eventName: Models.RegwallOpen['eventName'] = 'Regwall Open';
      trackEvent(eventName, {});
    } else {
      setShowPopup(!showPopup);
    }
  };

  const button = userToken ? (
    <button aria-label="profile" className={className} onClick={onClickSignin} type="button">
      <SvgImage reference="user" title="profile" />
    </button>
  ) : (
    <a aria-label="Login" className={className} href="#" onClick={onClickSignin}>
      <SvgImage reference="user" title="profile" />
    </a>
  );

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (showPopup) {
          setShowPopup(false);
        }
      }}
    >
      <div className={`mira-wrapper${userToken ? ' logged-in' : ' logged-out'}`}>
        {button}
        {popup}
      </div>
    </OutsideClickHandler>
  );
};

export default Me;
