const toggleBodyClass = (add: boolean) => {
  const bodyClass = 'prevent-scroll';
  if (add) {
    document.body.classList.add(bodyClass);
    // also add class to <html> element for iOS Safari
    document.documentElement.classList.add(bodyClass);
  } else {
    document.body.classList.remove(bodyClass);
    document.documentElement.classList.remove(bodyClass);
  }
};

export default toggleBodyClass;
