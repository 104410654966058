import { ContentResource } from '@p7s1/oasis-types';

const createUpdatePosition = ({ duration }: ContentResource) => {
  const step = duration ? Math.floor(duration / 5) : 123;
  const clearPos: (position: number) => number = duration
    ? pos => Math.floor(pos)
    : pos => Math.floor(pos / 1000);

  let max = 0;

  return (position: number) => {
    const pos = clearPos(position);
    if (pos % step === 0 && pos > max) {
      max = pos;
    }
  };
};

export default createUpdatePosition;
