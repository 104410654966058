import { createContext } from 'react';
import { ContentRoute } from '@src/interfaces';

export type ContextRoute = Pick<ContentRoute, 'layout' | 'meta'>;

const defaultRoute: ContextRoute = {
  layout: 'plain',
  meta: {
    coralContext: {},
    jsonLD: [],
    linkTags: [],
    metaTags: [],
    subtitle: '',
    somtag: {
      enabled: false,
      slots: {
        desktop: [],
        mobile: [],
      },
      taxonomy: ['other', 'other'],
    },
  },
};

const RouteContext = createContext<ContextRoute>(defaultRoute);

export default RouteContext;
