import React, { useContext } from 'react';
import { MessageComponent } from '@src/interfaces';
import RouteContext from '@src/components/Layout/route-context';

const Message = (props: MessageComponent) => {
  const route = useContext(RouteContext);
  return (
    <div
      className={`paragraph message-paragraph message-paragraph-${route.layout}`}
      // eslint-disable-next-line
      dangerouslySetInnerHTML={{ __html: props.html }}
    />
  );
};

export default Message;
