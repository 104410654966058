export type ScriptLoadOpts = {
  type?: 'text/plain' | 'text/javascript';
  attributes?: {
    [k in string]: string;
  };
  DATA_USERCENTRICS?: string;
};

const cache = new Map<string, Promise<unknown>>();

const scriptTag = <T>(src: string, global?: string, options?: ScriptLoadOpts) =>
  new Promise<T | void>((resolve, reject) => {
    const script: HTMLScriptElement = document.createElement('script');
    script.type = options?.type ?? 'text/javascript';
    script.src = src;
    if (options?.attributes) {
      Object.entries(options.attributes).forEach(([name, value]) => {
        script.setAttribute(name, value);
      });
    }
    if (options?.DATA_USERCENTRICS) {
      script.setAttribute('data-usercentrics', options.DATA_USERCENTRICS);
    }
    script.addEventListener('load', () => {
      if (global) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const value: T = (window as any)[global];
        if (value) {
          resolve(value);
        }
      } else {
        resolve();
      }
    });
    script.addEventListener('error', reject);
    document.head.appendChild(script);
  });

const loadCached = <T>(src: string, global?: string, options?: ScriptLoadOpts) => {
  if (cache.has(src)) {
    return cache.get(src) as Promise<T>;
  }
  const promise = scriptTag<T>(src, global, options);
  cache.set(src, promise);
  return promise;
};

export const loadScript = <T>(src: string, global?: string, options?: ScriptLoadOpts) =>
  loadCached<T>(src, global, options) as Promise<T>;
