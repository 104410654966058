import React, { ComponentType, useContext } from 'react';
import { ContentMeta, SomtagSlot } from '@src/interfaces';
import clientSlot from '@src/utils/somtag_clientslot';
import { View } from '@src/utils/cmp';
import { SomMarketing } from '@p7s1/oasis-types/src/content-resource';
import { AdsContext } from '@src/context/ads';

export interface SomtagCallbackResult {
  type: string;
  data: {
    adConfig: {
      height: number | undefined;
      slot: string;
    };
    slotName: string;
    options: {
      container: string;
    };
  };
}

export type SomMarketingValues = {
  advertiser: string;
  config: {
    id: string;
    consent: {
      tcfVersion: 2;
      gdprConsent?: string;
    };
  };
  syndication: {
    content: '';
    partner: '';
  };
  display: {
    slots: {
      [k in SomtagSlot]?: { enabled: boolean };
    };
  };
} & SomMarketing;

export const somMarketingDefault: SomMarketingValues = {
  advertiser: 'SOM_AT',
  config: {
    id: 'puls24at',
    consent: {
      tcfVersion: 2,
    },
  },
  display: {
    slots: {},
  },
  syndication: {
    content: '',
    partner: '',
  },
  taxonomy: {
    channels: ['opt-ros'],
    content: 'content',
    topics: {},
  },
};

export interface Somtag {
  cmd(
    string1: string,
    string2: string | string[] | typeof somMarketingDefault,
    config?: {
      container: string;
    },
    callback?: (_: string, result: SomtagCallbackResult) => void,
  ): void;
}

declare global {
  interface Window {
    somtag: Somtag;
    initValues?: SomMarketingValues;
  }
}

let $somtag: Promise<Somtag>;

export const getSomtag: () => typeof $somtag = () => {
  if (!$somtag) {
    $somtag = new Promise<Somtag>(resolve => {
      const onInitialized = (e: CustomEvent<{ view?: View }>) => {
        if (e.detail) {
          const { view } = e.detail;
          if (view !== 'configuration') {
            return;
          }
        }
        document.removeEventListener('cmp:initialized', onInitialized as EventListener);
        document.removeEventListener('cmp:confirmed', onInitialized as EventListener);
        document.removeEventListener('cmp:saved', onInitialized as EventListener);
        resolve(window.somtag);
      };
      document.addEventListener('cmp:initialized', onInitialized as EventListener);
      document.addEventListener('cmp:confirmed', onInitialized as EventListener);
      document.addEventListener('cmp:saved', onInitialized as EventListener);
    });
  }
  return $somtag;
};

interface SomtagInitProps {
  meta: ContentMeta;
}

export const withAdsEnabled = <T,>(Component: ComponentType<T>) => (props: T) => {
  const { adsEnabled } = useContext(AdsContext);
  if (!adsEnabled) {
    return null;
  }
  return <Component {...props} />;
};

const getTaxonomChannels = (meta: ContentMeta): string[] => meta.somtag.taxonomy;

export const SomtagInit = ({ meta }: SomtagInitProps) => {
  const displaySlots: SomMarketingValues['display']['slots'] = {};

  Object.keys(meta.somtag.slots).forEach(display => {
    const slots = meta.somtag.slots[display as 'mobile' | 'desktop'] as SomtagSlot[];
    slots
      .concat(['skyscraper1', 'fullbanner2', 'mbanner1'])
      .map(slot => clientSlot(slot, [display]))
      .filter(slot => slot !== undefined)
      .forEach(slot => {
        displaySlots[slot as SomtagSlot] = { enabled: true };
      });
  });

  const initValues: SomMarketingValues = {
    ...somMarketingDefault,
    ...{
      display: {
        slots: displaySlots,
      },
    },
    ...{
      taxonomy: {
        channels: getTaxonomChannels(meta),
        content: 'content',
        topics: {},
      },
    },
  };

  return getSomtag().then(somtag => somtag.cmd('init', initValues));
};
