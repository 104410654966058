import React, { FC, useState, useRef, useContext } from 'react';
import type { MenuTree } from '@src/interfaces';
import Link from 'next/link';
import Overlay from '@src/components/Navigation/Overlay';
import ThemeToggle from '@src/components/Navigation/ThemeToggle';
import SvgImage from '@src/components/SvgImage';
import stripUrlPlaceholder from '@src/utils/strip-url-placeholder';
import useOasisStickyPlayer from '@src/utils/hooks/useOasisStickyPlayer';
import Me from '@src/components/auth/Me';
import RouteContext from '@src/components/Layout/route-context';
import {trackEvent} from "@src/utils/et";
import {Models} from "@data/et-web-api";
import styles from './styles.module.scss';
import ScreenNames = Models.ScreenNames;

type NavigationProps = {
  menu: MenuTree;
  topics: MenuTree;
  trending: MenuTree;
  socials: Record<string, string>;
  onOverlayToggle(open: boolean): void;
};

const Navigation: FC<NavigationProps> = ({
  menu,
  topics,
  trending,
  socials,
  onOverlayToggle,
}) => {
  const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false);
  const menuRef = useRef<HTMLButtonElement>(null)
  const { onOasisTrigger } = useOasisStickyPlayer();
  const route = useContext(RouteContext);

  const onClickLink = (link: string, title: string, screenName: ScreenNames) => {
    const eventName: Models.NavigationClick['eventName'] = 'Navigation Click';
    const eventPayload: Models.NavigationClick['eventPayload'] = {
      targetScreenName: screenName,
      navigationLabel: title,
      targetUrl: stripUrlPlaceholder(link) ?? '',
    }
    trackEvent(eventName, eventPayload);
  }

  const openOverlay = () => {
    onOverlayToggle(true);
    setIsOverlayOpen(true);

    const eventName: Models.BurgerMenuClick['eventName'] = 'Burger Menu Click';
    trackEvent(eventName, {});
  };

  const closeOverlay = () => {
    menuRef.current?.focus();

    onOverlayToggle(false);
    setIsOverlayOpen(false);
  };

  const seoHeadline = (pageType: string | null | undefined, title: string) => {
    if (!["article", "video", "404"].includes(pageType as string) && title.toLowerCase() !== "home") {
      return <h1 className={styles.category}>{title}</h1>
    }
    return <span className={styles.category}>{title}</span>
  };

  const submenuLine = trending.links.map(({ id, title: heading, children }) => (
    <li key={id}>
      <span className={styles.lineTopic}>{heading}</span>
      {children.map(({ id: childId, link, title, attributes }) => (
        <Link key={childId} as={stripUrlPlaceholder(link)} href="/">
          <a {...attributes} className={styles.lineAnchor} onClick={() => onClickLink(link, title, 'Home')}>
            {title}
          </a>
        </Link>
        ))}
    </li>
    ));

  return (
    <nav className={styles.navigation}>
      <div aria-hidden={isOverlayOpen}>
        <div className={styles.navbar}>
          <button
            aria-label="Puls24.at Live"
            className={styles.live}
            onClick={() => {
              onOasisTrigger();
              onClickLink('', 'Live TV Player', 'Live TV');
            }}
            type="button"
          >
            PULS 24 Live
          </button>

          <Me className={styles.profile} />

          <button
            ref={menuRef}
            aria-expanded={isOverlayOpen}
            aria-label="Hauptmenü"
            className={styles.menu}
            onClick={openOverlay}
            type="button"
          >
            <SvgImage reference="menu" title="Menu" />
          </button>
        </div>

        <div className={styles.header}>
          <div className={`${styles.banner} container`}>
            <Link href="/">
              <a className={styles.logo}>
                <SvgImage reference="logo" title="puls24.at" />
              </a>
            </Link>

            { seoHeadline(route.meta.coralContext.pageType, route.meta.subtitle) }

            <ThemeToggle className={styles.themeMobile} />
          </div>

          <div className={`${styles.linksContainer} container`}>
            <ul className={styles.links}>
              {topics.links.map(({ id, link, title , attributes}) => (
                <li key={id}>
                  <Link as={stripUrlPlaceholder(link)} href="/">
                    <a {...attributes} className={styles.link} onClick={() => onClickLink(link, title, 'Home')}>{title}</a>
                  </Link>
                </li>
              ))}
            </ul>

            <div className={styles.linksDesktop}>
              <Link as="/live" href="/">
                <a
                  aria-label="Puls24.at Live"
                  className={styles.liveDesktop}
                  onClick={() => {
                    onClickLink('', 'Live TV', 'Live TV');
                  }}
                  type="button"
                >
                  Live
                </a>
              </Link>

              <Me className={styles.profileDesktop} />

              <ThemeToggle className={styles.themeDesktop} toggle={false} />

              <button
                ref={menuRef}
                aria-expanded={isOverlayOpen}
                aria-label="Hauptmenü"
                className={styles.menuDesktop}
                onClick={openOverlay}
                type="button"
              >
                <SvgImage reference="menu" title="Menu" />
              </button>
            </div>
          </div>
          <ul className={`${styles.lines} container`}>
            {submenuLine}
          </ul>
        </div>
      </div>

      {isOverlayOpen ? <Overlay
        links={menu.links}
        onClose={closeOverlay}
        socials={socials}
        /> : null}
    </nav>
  );
};

export default Navigation;
