import React, { ReactNode, useEffect, useState } from 'react';
import Countdown, { CountdownRenderProps, zeroPad } from 'react-countdown';
import { Schedule } from '@src/interfaces';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface CountdownComponent {
  schedule?: Schedule;
  children: ReactNode;
}

const CipherBlock = ({ digit, description }: { digit: number; description: string }) => {
  // apply zeroPad to prevent single digit number strings
  // split number into first and second digit
  const [first, second] = zeroPad(digit).toString();
  return (
    <div className={styles.cipherBlock}>
      <span className={styles.digit}>{first}</span>
      <span className={styles.digit}>{second}</span>
      <span className={styles.description}>{description}</span>
    </div>
  );
};

// Renderer callback with conditional
const CountdownRenderer = ({
  days,
  hours,
  minutes,
  seconds,
  text,
}: CountdownRenderProps & { text: Schedule['countdownText'] }) => (
  <div className={styles.countdown}>
    <div className={styles.wrapper}>
      {text ? <h3 className={classNames(styles.text, 'component-title')}>{text}</h3> : null}

      <div className={styles.display}>
        <CipherBlock description={`Tag${days === 1 ? '' : 'en'}`} digit={days} />

        <CipherBlock description={`Stunde${hours === 1 ? '' : 'n'}`} digit={hours} />

        <CipherBlock description={`Minute${minutes === 1 ? '' : 'n'}`} digit={minutes} />

        <CipherBlock description={`Sekunde${seconds === 1 ? '' : 'n'}`} digit={seconds} />
      </div>
    </div>
  </div>
);

type HiddenState = {
  name: 'hidden';
  changeAt?: Schedule['showAt'];
};
type VisibleState = {
  name: 'visible';
  changeAt?: Schedule['hideAt'];
};
type CountdownState = {
  name: 'countdown';
  changeAt: number;
  text: Schedule['countdownText'];
};
type ScheduleState = HiddenState | VisibleState | CountdownState;

const stateOf = (schedule?: Schedule): ScheduleState => {
  const now = Date.now() / 1000;
  if (!schedule) {
    return {
      name: 'visible',
    };
  }
  if (schedule.hideAt && schedule.hideAt < now) {
    return {
      name: 'hidden',
    };
  }
  if (schedule.showAt && now < schedule.showAt) {
    return schedule.countdown
      ? ({
          name: 'countdown',
          changeAt: schedule.showAt,
          text: schedule.countdownText,
        } as CountdownState)
      : {
          name: 'hidden',
          changeAt: schedule.showAt,
    };
  }
  return {
    name: 'visible',
    changeAt: schedule.hideAt,
  };
};

const CountdownCore = ({ schedule, children }: CountdownComponent) => {
  const initialState = stateOf(schedule);
  const [state, setState] = useState<ScheduleState>(initialState);

  useEffect(() => {
    if (!state.changeAt) {
      // eslint-disable-next-line
      return () => {};
    }
    const now = Date.now();
    const timeout = state.changeAt * 1000 - now;
    if (timeout < 0) {
      // eslint-disable-next-line
      return () => {};
    }
    const timer = setTimeout(() => setState(stateOf(schedule)), timeout);
    return () => clearTimeout(timer);
  }, [state, schedule]);

  switch (state.name) {
    case 'hidden':
      return null;

    case 'countdown':
      return (
        <Countdown
          date={state.changeAt * 1000}
          renderer={(props: CountdownRenderProps) =>
            CountdownRenderer({
              ...props,
              text: state.text,
            })
          }
        />
      );

    default:
      return <>{children}</>;
  }
};

export default CountdownCore;
