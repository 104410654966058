import type { ResponsiveComponent, Component } from '@src/interfaces';
import React, { useContext } from 'react';
import dynamic from 'next/dynamic';
import { AdsContext } from '@src/context/ads';
import TeaserList from '@src/components/TeaserList';
import TeaserCarousel from '@src/components/TeaserCarousel/TeaserCarousel';
import Error from '@src/components/Error/Error';
import Somtag from '@src/components/Ad/Somtag';
import SingleTeaser from '@src/components/SingleTeaser/SingleTeaser';
import Banner from '@src/components/Banner/Banner';
import Message from '@src/components/Message/Message';

const ApaVisualsSoccer = dynamic(() => import('@src/components/ApaVisuals/Soccer'));
const ApElections = dynamic(() => import('@src/components/ApElections/ApElections'));
const Article = dynamic(() => import('@src/components/Article/Article'));
const CleverpushWidget = dynamic(() => import('@src/components/Cleverpush/Widget'));
const HockeyDataComponent = dynamic(() => import('@src/components/Hockey/HockeyDataComponent'));
const HockeyTeamIcons = dynamic(() => import('@src/components/Hockey/TeamIcons'));
const Iframe = dynamic(() => import('@src/components/Iframe/Iframe'), { ssr: false });
const Podigee = dynamic(() => import('@src/components/Podigee/Podigee'));
const Kicktipp = dynamic(() => import('@src/components/Kicktipp/Kicktipp'));
const ScriptLoader = dynamic(() => import('@src/components/ScriptLoader/ScriptLoader'));
const NewsFeed = dynamic(() => import('@src/components/NewsFeed/NewsFeed'));
const OutbrainWidget = dynamic(() => import('@src/components/Outbrain/Widget'));
const Persons = dynamic(() => import('@src/components/Persons/Persons'));
const Riddle = dynamic(() => import('@src/components/Riddle/Riddle'));
const SearchResult = dynamic(() => import('@src/components/SearchResult'));
const SendinblueWidget = dynamic(() => import('@src/components/SendinblueWidget/SendinblueWidget'));
const SubscriptionForm = dynamic(() => import('@src/components/Sendinblue/SubscriptionForm'));
const Submenu = dynamic(() => import('@src/components/Submenu/Submenu'));
const TeaserGrid = dynamic(() => import('@src/components/TeaserGrid'));
const TeaserSlider = dynamic(() => import('@src/components/TeaserSlider/TeaserSlider'), {
  ssr: false,
});
const TeasersFromResource = dynamic(() => import("@src/components/TeasersFromResource"));
const StorySlider = dynamic(() => import('@src/components/StorySlider/StorySlider'), {
  ssr: false,
});

const TvGuide = dynamic(() => import('@src/components/TvGuide/TvGuide'));
const TvShow = dynamic(() => import('@src/components/cumulus/TvShow'));
const Video = dynamic(() => import('@src/components/Video/Video'));
const Voting = dynamic(() => import('@src/components/Eve/Voting'));
const WeatherText = dynamic(() => import('@src/components/WeatherText/WeatherText'));
const WeatherImageSlideshow = dynamic(() => import('@src/components/WeatherImage/Slideshow'));
const WeatherImageTwoRow = dynamic(() => import('@src/components/WeatherImage/TwoRow'));
const WeatherImageOneRow = dynamic(() => import('@src/components/WeatherImage/OneRow'));
const PersonProfile = dynamic(() => import('@src/components/PersonProfile'));
const QualifioComponent = dynamic(() => import('@src/components/QualifioSection'), {
  ssr: false,
});
const DiscoveryLinks = dynamic(() => import('@src/components/DiscoveryLinks'))
const TvTimeline = dynamic(() => import('@src/components/TvTimeline'))

interface ComponentProps extends ResponsiveComponent {
  component: Component;
}

const DynamicComponent = ({ component, regionWidth }: ComponentProps) => {
  const { somtagInitialized } = useContext(AdsContext);

  switch (component.type) {
    case 'apElections':
      return <ApElections {...component} />;

    case 'apaVisualsSoccer':
      return <ApaVisualsSoccer {...component} />;

    case 'article':
      return <Article {...component} />;

    case 'banner':
      return <Banner {...component} />;

    case 'cleverpushWidget':
      return <CleverpushWidget {...component} />;

    case 'cumulusTvShow':
      return <TvShow {...component} />;

    case 'eve':
      return <Voting {...component} />;

    case 'error':
      return <Error {...component} />;

    case 'hockeydataWidget':
      return <HockeyDataComponent {...component} />;

    case 'hockeyTeamIcons':
      return <HockeyTeamIcons {...component} />;

    case 'iframe':
      return <Iframe {...component} />;

    case 'podigeeWidget':
      return <Podigee {...component} />

    case 'kicktippWidget':
      return <Kicktipp {...component} />;

    case 'message':
      return <Message {...component} />;

    case 'scriptWidget':
      return <ScriptLoader {...component} />;

    case 'weatherText':
      return <WeatherText {...component} />;

    case 'weatherImageSlideshow':
      return <WeatherImageSlideshow {...component} />;

    case 'weatherImageTwoRow':
      return <WeatherImageTwoRow {...component} />

    case 'weatherImageOneRow':
      return <WeatherImageOneRow {...component} />

    case 'newsFeed':
      return <NewsFeed {...component} />;

    case 'outbrainWidget':
      return <OutbrainWidget {...component} />;

    case 'persons':
      return <Persons />;

    case 'riddle':
      return <Riddle {...component} />;

    case 'searchResult':
      return <SearchResult {...component} />;

    case 'sendinblueForm':
      return <SubscriptionForm {...component} />;

    case 'sendinblueWidget':
      return <SendinblueWidget />;

    case 'singleTeaser':
      return <SingleTeaser {...component} />;

    case 'somtag':
        return somtagInitialized
        ? <Somtag {...component} routeAware />
        : null;

    case 'submenu':
      return <Submenu {...component} />;

    case 'teaserCarousel':
      return <TeaserCarousel {...component} regionWidth={regionWidth} />;

    case 'teaserGrid':
      return <TeaserGrid {...component} regionWidth={regionWidth} />;

    case 'teaserList':
      return <TeaserList {...component} regionWidth={regionWidth} />;

    case 'teaserSlider':
      return <TeaserSlider {...component} regionWidth={regionWidth} />;

    case 'teasersFromResource':
      return <TeasersFromResource {...component} regionWidth={regionWidth} />;

    case 'storySlider':
      return <StorySlider {...component} regionWidth={regionWidth} />;

    case 'tvGuide':
      return <TvGuide {...component} />;

    case 'video':
      return <Video {...component} />;

    case 'user':
      return <PersonProfile {...component}/>

    case 'qualifio':
      return <QualifioComponent {...component}/>

    case 'discoveryLinks':
      return <DiscoveryLinks {...component}/>

    case 'tvTimeline':
      return <TvTimeline {...component}/>

    default:
      console.debug(`Unknown component type ${component.type}`);
      return null;
  }
};

export default DynamicComponent;
