import getConfig from 'next/config';
import { loadScript } from '@src/utils/scripts';

declare global {
  interface Window {
    cmp?: ConsentApi;
  }
}

export const DATA_USERCENTRICS = 'data-usercentrics';

// @see https://gitlab.p7s1.io/cmp/component/-/tree/master/src/typings

export type Consent = {
  id: string;
  name: string;
  categorySlug: string;
  consent: {
    status: boolean;
  };
  legitimateInterestConsent: {
    status: boolean;
  };
};

export type Vendor = {
  type: 'tcf' | 'default' | 'fake';
  id: string;
  name: string;
  consent: {
    status: boolean;
  };
  legitimateInterestConsent: {
    status: boolean;
  };
};

export type ConsentApi = {
  isInitialized: () => boolean;
  hasFailed: () => boolean;
  getConsents: () => Consent[];
  getConsent: (id: string) => Consent | undefined;
  getConsentStatus: (id: string) => boolean;
  getVendors: () => Vendor[];
  getVendor: (id: string) => Vendor | undefined;
  getVendorStatus: (id: string) => boolean;
  getControllerId: () => string | undefined;
  getBundleId: () => string | undefined;
  getSettingsId: () => string | undefined;
  showBanner: (options?: { step: Step }) => void;
  hideBanner: () => void;
  showBackdrop: () => void;
  hideBackdrop: () => void;
  refresh: () => void;
  showStep: (options?: { step: Step; context?: Context }) => void;
  clear: () => void;
  setModifiers: (modifiers: Modifier[]) => void;
};

export type Offset = {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
};

export type Modifier = 'default' | 'tcf2-min' | 'tcf2-max';

type Step = 'splash' | 'configuration' | 'vendors' | 'details';

type Context = {
  [key: string]: unknown;
};

export type Language = 'de' | 'en';

export type View = 'none' | 'configuration' | 'splash';

let cmp: Promise<ConsentApi>;

const getCmp: () => Promise<ConsentApi> = () => {
  if (!cmp) {
    const {
      publicRuntimeConfig: { USERCENTRICS_SETTINGS_ID },
    } = getConfig();
    if (USERCENTRICS_SETTINGS_ID) {
      cmp = new Promise<ConsentApi>(resolve => {
        const src = `https://s.p7s1.io/cmp/${USERCENTRICS_SETTINGS_ID}-web.js`;
        loadScript<ConsentApi>(src, 'cmp')
          .then(_cmp => resolve(_cmp))
          .catch(reason => console.log('Could not load', src, reason));
      });
    } else {
      cmp = new Promise<ConsentApi>(() => {
        console.warn('Missing USERCENTRICS_SETTINGS_ID');
      });
    }
  }
  return cmp;
};

export default getCmp;
