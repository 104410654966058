type ClassNameOperationChain = string | [boolean, string, string, string?];

/**
 * Works like this:
 *
 *```
 *const myBool = true;
 *const hasTitle = true;
 *```
 * ```
 * <div className={getClassName([
 *  'my-basic-class',
 *  [myBool, 'some-base-class-', 'bool-true', 'bool-false']
 *  [hasTitle, '', 'has-title']
 * ])}>Lorem Ipsum Dolorem</div>
 * ```
 *
 * The definition above will render the following:
 *
 * ```
 * <div class="my-basic-class some-base-class-bool-true has-title">Lorem Ipsum Dolorem</div>
 * ```
 */

const getClassName = (props: ClassNameOperationChain[]) =>
  props
    .map(operation => {
      if (typeof operation === 'string') {
        return operation;
      }
      const [condition, prefix, postFixTrue, postFixFalse] = operation;
      return `${prefix}${condition ? postFixTrue : postFixFalse || ''}`;
    })
    .join(',')
    .split(',')
    .join(' ');

export default getClassName;
