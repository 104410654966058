import { SomtagSlot } from '@src/interfaces';
import BREAKPOINTS from './breakpoints';

const DESKTOP_AD_SLOTS = new Map<SomtagSlot, SomtagSlot>([['minread1', 'inread1']]);

const clientSlot: (adSlot: SomtagSlot, display: string[]) => SomtagSlot | undefined = (
  adSlot,
  display,
) => {
  if (!process.browser) {
    return undefined;
  }
  // define translation map for statically defined positions
  // for referring to slots like so: [<mobile>, <desktop>]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const screenWidth = (window as any).innerWidth ?? 0;
  const desktopAdSlot = DESKTOP_AD_SLOTS.get(adSlot);

  if (screenWidth >= BREAKPOINTS.tablet && desktopAdSlot) {
    return desktopAdSlot;
  }

  if (adSlot === 'fullbanner2' && screenWidth < BREAKPOINTS.tablet) {
    return undefined;
  }
  if (adSlot === 'mbanner1' && screenWidth >= BREAKPOINTS.tablet) {
    return undefined;
  }

  if (adSlot === 'skyscraper1' && screenWidth < BREAKPOINTS.mobile) {
    return undefined;
  }

  if (display && display.length) {
    if (screenWidth >= BREAKPOINTS.desktop && !display.includes('desktop')) {
      return undefined;
    }
    if (screenWidth < BREAKPOINTS.desktop && !display.includes('mobile')) {
      return undefined;
    }
  }

  return adSlot;
};

export default clientSlot;
