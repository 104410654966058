import type { SomtagSlot } from '@src/interfaces';
import { useState, useEffect } from 'react';
import BREAKPOINTS from '../breakpoints';

const DESKTOP_AD_SLOTS = new Map<SomtagSlot, SomtagSlot>([['minread1', 'inread1']]);

const useSometagClientSlot = (adSlot: SomtagSlot, display: string[]) => {
  const [clientSlot, setClientSlot] = useState<string | undefined>(adSlot);

  useEffect(() => {
    const screenWidth = window.innerWidth ?? 0;
    const desktopAdSlot = DESKTOP_AD_SLOTS.get(adSlot);

    if (screenWidth >= BREAKPOINTS.tablet && desktopAdSlot) {
      setClientSlot(desktopAdSlot);
    }
    if (adSlot === 'fullbanner2' && screenWidth < BREAKPOINTS.tablet) {
      setClientSlot(undefined);
    }
    if (adSlot === 'mbanner1' && screenWidth >= BREAKPOINTS.tablet) {
      setClientSlot(undefined);
    }
    if (adSlot === 'skyscraper1' && screenWidth < BREAKPOINTS.mobile) {
      setClientSlot(undefined);
    }
    if (display && display.length) {
      if (screenWidth >= BREAKPOINTS.desktop && !display.includes('desktop')) {
        setClientSlot(undefined);
      }
      if (screenWidth < BREAKPOINTS.desktop && !display.includes('mobile')) {
        setClientSlot(undefined);
      }
    }
  }, [adSlot, display]);

  return clientSlot;
};

export default useSometagClientSlot;
