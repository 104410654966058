import type { FC } from 'react';
import React, { useContext } from 'react';
import { AdsContext } from '@src/context/ads';
import Somtag, { SomtagProps } from '@src/components/Ad/Somtag';

type FullbannerProps = {
  idMobile: string;
  idDesktop: string;
};

const Fullbanner: FC<FullbannerProps> = ({ idMobile, idDesktop }) => {
  const { somtagInitialized, adsEnabled } = useContext(AdsContext);

  if (!adsEnabled) {
    return null;
  }

  const mobile: SomtagProps = {
    id: idMobile,
    slot: 'mbanner1',
    display: ['mobile'],
    type: 'somtag',
    adContentHeight: 50,
  };
  const desktop: SomtagProps = {
    id: idDesktop,
    slot: 'fullbanner2',
    display: ['desktop'],
    type: 'somtag',
    adContentHeight: 90,
  };

  return (
    <div className="row">
      <div className="col-12">
        {somtagInitialized ? <>
          <Somtag {...mobile} />

          <Somtag {...desktop} />
        </> : null}
      </div>
    </div>
  );
};

export default Fullbanner;
