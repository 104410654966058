import { MenuTree } from '@src/interfaces';
import fetchJson from '@src/utils/api/fetch';

const fetchMenu = async (name: string): Promise<MenuTree> => {
  if (typeof window !== 'undefined' && (name === 'main' || name === 'footer' || name === 'topics' || name === 'trending')) {
    // eslint-disable-next-line no-underscore-dangle
    return window.__NEXT_DATA__.props.pageProps.menus[name];
  }
  return fetchJson<MenuTree>({
    url: `/api/headless/p7s1p4/menu/puls24at/${name}`,
  })
    .then(response => response.body)
    .catch(err => {
      console.error('fetchMenu', err);
      return { links: [] };
  });

};

export default fetchMenu;
