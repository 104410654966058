import type { FC } from 'react';
import type { Teaser } from '@src/interfaces';
import React, { MouseEventHandler } from 'react';
import Link from 'next/link';
import stripUrlPlaceholder from '@src/utils/strip-url-placeholder';
import getFormattedDate from '@src/utils/get-formatted-date';
import SvgImage from '@src/components/SvgImage';
import CustomImage from '@src/components/CustomImage';
import styles from './styles.module.scss';

type TinyTeaserProps = Teaser & {
  contentBox?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

const TinyTeaser: FC<TinyTeaserProps> = ({
  title,
  url,
  videoDuration,
  image,
  contentBox,
  advertorial,
  updated,
  onClick,
}) => {
  const vrContentbox = contentBox ? {
    'data-vr-contentbox': contentBox,
    'data-vr-contentbox-url': url,
  } : {};
  const vrHeadline = contentBox ? { 'data-vr-headline': '' } : {};

  return (
    <div className={styles.container}>
      <Link as={stripUrlPlaceholder(url)} href="/">
        <a
          className={styles.anchor}
          onClick={onClick}
          {...vrContentbox}
        >
          {image ? <div className={styles.illustration}>
            <CustomImage {...image} size='xsmall'/>

            {videoDuration ? <SvgImage
              className={styles.play}
              reference="play-circle"
              title="Video"
                /> : null}
          </div> : null}

          <div>
            <h3
              className={styles.title}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: title }}
              {...vrHeadline}
            />

            {advertorial ? <div className={styles.meta}>Anzeige</div> : null}

            {updated ? <div className={styles.meta}>{getFormattedDate(updated)}</div> : null}
          </div>
        </a>
      </Link>
    </div>
  );
};

export default TinyTeaser;
