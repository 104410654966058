import type { FC, ReactNode } from 'react';
import React, { useState, useEffect, useMemo, useCallback, createContext } from 'react';
import {Models} from "@data/et-web-api";
import {trackEvent} from "@src/utils/et";

export type ThemeContextType = {
  isDarkMode: boolean | null;
  toggleTheme(): void;
};

export type ThemeContextProps = {
  children: React.ReactNode;
};

export const ThemeContext = createContext<ThemeContextType>({
  isDarkMode: false,
  toggleTheme: () => null,
});

const ThemeContextProvider: FC<ThemeContextProps> = ({
  children,
} : {children: ReactNode}) => {
  const [isDarkMode, setIsDarkMode] = useState<boolean | null>(null);

  useEffect(() => {
    // First load check, attribute set by /assets/theme.js
    if (isDarkMode === null) {
      setIsDarkMode(document.documentElement.getAttribute('data-theme') === 'dark');
      return;
    }

    const theme = isDarkMode ? 'dark' : 'light';

    window.localStorage.setItem('P24_theme', theme);
    document.documentElement.setAttribute('data-theme', theme)
  }, [isDarkMode]);

  const toggleTheme = useCallback(() => {
    setIsDarkMode(!isDarkMode);

    const eventName: Models.SettingsClick['eventName'] = 'Settings Click';
    const eventPayload: Models.SettingsClick['eventPayload'] = {
      settingsLabel: !isDarkMode ? 'dark mode' : 'light mode',
    }
    trackEvent(eventName, eventPayload);
  }, [isDarkMode])

  const contextValue = useMemo(
    () => ({ isDarkMode, toggleTheme }),
    [isDarkMode, toggleTheme],
  );

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
}

export default ThemeContextProvider;
