import React from 'react';
import Image, { ImageProps } from 'next/image';
import { Image as ImageModel } from '@src/interfaces';
import getConfig from 'next/config';
import { ImageLoader } from 'next/dist/client/image';
import styles from './ImageComponent.module.scss';

export type ImageSize =
  | 'xsmall'
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xsmall_square'
  | 'large_uncropped';

type ImageComponentProps = {
  image?: ImageModel;
  size: ImageSize;
  children?: React.ReactNode;
  showCredits?: boolean;
  showDescription?: boolean;
} & Pick<ImageProps, 'priority'>;

const calcHeightMultiplier = (
  image: NonNullable<ImageModel>,
  isSquare: boolean,
  isUncropped: boolean,
): number => {
  if (isSquare) {
    return 1;
  }
  if (isUncropped) {
    return image.height / image.width;
  }
  return 0.5625;
};

function calcHeight(image: NonNullable<ImageModel>, size: ImageSize) {
  const isUncropped = size.endsWith('_uncropped');
  const isSquare = size.endsWith('_square');
  const heightMultiplier = calcHeightMultiplier(image, isSquare, isUncropped);
  const height = isUncropped ? image.height : image.width * heightMultiplier;
  const paddingBottom = `${(heightMultiplier * 100).toFixed(2)}`;
  return { heightMultiplier, height, paddingBottom };
}

const ImageComponent = ({
  image,
  size,
  priority = false,
  children = null,
  showCredits = true,
  showDescription = false,
}: ImageComponentProps) => {
  if (!image) {
    return null;
  }

  const { heightMultiplier, height } = calcHeight(image, size);

  const myLoader: ImageLoader = ({ src, width }) => {
    const { publicRuntimeConfig } = getConfig();

    // case 1 large_uncropped -> `/hera_dyn_${width}w_uncropped/`
    // case 2 xsmall / xsmall_square -> `/hera_dyn_${width}w_square/`
    // default: heightMultiplier
    let style: string;
    switch (size) {
      case 'large_uncropped':
        style = `/hera_dyn_${width}w_uncropped/`;
        break;
      case 'xsmall':
      case 'xsmall_square':
        style = `/hera_dyn_${width}w_square/`;
        break;

      default:
        style = `/hera_dyn_${width}w_${Math.round(width * heightMultiplier)}h/`;
    }

    return `${publicRuntimeConfig.PUBLIC_IMAGE_HOST}${src}`.replace('/large/', style);
  };

  // const { paddingBottom } = calcHeight(image, size);

  let sizes: string;
  switch (size) {
    case "medium":
      sizes = "768px";
      break;
    case "small":
      sizes = "536px";
      break;
    case "xsmall_square":
      sizes = "227px";
      break;
    default:
      // default value from react image
      sizes = "100vw";
  }

  const nextImage = (
    <Image
      alt={image.alt}
      height={height}
      layout="responsive"
      loader={myLoader}
      priority={priority}
      quality={100}
      sizes={sizes}
      src={image.url}
      width={image.width}
    />
  );

  return (
    <>
      <div className={`${styles.imageComponentWrapper} imageComponentWrapper`}>
        <div className={styles.imageAreaWrapper}>
          {nextImage}

          {showCredits && image?.credits ? <span className={styles.imageCredits}>{image.credits}</span> : null}
        </div>

        {children}
      </div>

      {showDescription && image?.description ? <figcaption>
        <span
          className={styles.imageDescription}
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{ __html: image.description }}
          />
      </figcaption> : null}
    </>
  );
};

export default ImageComponent;
