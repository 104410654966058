import type {Section} from '@src/interfaces';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Link from 'next/link';
import stripUrlPlaceholder from '@src/utils/strip-url-placeholder';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import RegionComponent from '@src/components/SectionComponent/Region';
import classNames from 'classnames';
import {useRouter} from "next/router";
import { Models } from '@data/et-web-api';
import { trackEvent } from '@src/utils/et';
import styles from './styles.module.scss';

const TabsSection = ({
  regions,
  columnWidths,
  title,
  link,
  displayTitle,
}: Section) => {
  const router = useRouter();
  const [tabIndex, setTabIndex] = useState(0);
  const sectionId = useMemo(() =>
    `${(title ?? 'tabs').split(' ').join('-').toLowerCase()}`,
    [title]
  );

  useEffect(() => {
    const urlParam = window.location.hash
    if (urlParam) {
      const splitted = urlParam.split('--')
      if (splitted[0] !== `#${sectionId}`) {
        return;
      }
      const selectedTabIndex = parseInt(`${urlParam.split('--').slice(-1)}`, 10)
      setTabIndex(selectedTabIndex < regions.length && !Number.isNaN(selectedTabIndex) ? selectedTabIndex : 0);
      const anchor = document.getElementById(sectionId)
      if (anchor) {
        window.scrollBy({
          top: anchor?.getBoundingClientRect().top,
          behavior: 'smooth',
        })
      }
    }
  }, [regions.length, sectionId]);

  const onSelectTab = useCallback((index: number) => {
    // Tracking.
    const eventName: Models.TabClick['eventName'] = 'Tab Click';
    const eventPayload: Models.TabClick['eventPayload'] = {
      tabLabel: regions[index].optionalSettings.label,
    }
    trackEvent(eventName, eventPayload);

    // Setting URL param.
    let fragment = '#'
    if (index) {
      fragment = `#${sectionId}--${index}`
    }
    router.push(fragment, undefined, { scroll: false });

  }, [regions, router, sectionId])

  return (
    <section className="section section--tabs row" id={`${sectionId}`}>
      <div className="col-12">
        {displayTitle && title ? <h2 className="component-title section-title col-12">
          {link ? (
            <Link as={stripUrlPlaceholder(link)} href="/">
              <a className="teaser-anchor">{title}</a>
            </Link>
          ) : title}
        </h2> : null}
        <Tabs className={styles.tabs}
          onSelect={(index: number) => {
            setTabIndex(index)
            onSelectTab(index);
          }}
          selectedIndex={tabIndex}
          selectedTabClassName={styles.tabSelected}
          selectedTabPanelClassName={styles.tabPanelSelected}
        >
          <TabList className={styles.tabList}>{regions.map((region, index) => <Tab key={index} className={styles.tab}>{region.optionalSettings.label}</Tab>)}</TabList>
          {regions.map((region, index) =>
            <TabPanel key={index} className={classNames(styles.tabPanel, 'row')}>
              <RegionComponent
                key={`r${index}`}
                width={columnWidths}
                {...region}
              />
            </TabPanel>)}
        </Tabs>
      </div>
    </section>
  )
};

export default TabsSection;
