import getConfig from 'next/config';
import { setTimeout } from 'timers';

export type FetchOpts = {
  readonly url: string;
  readonly timeout?: number;
}

export type FetchResponse<T> = {
  readonly body: T;
} & Pick<Response, 'headers' | 'ok' | 'status'>;

async function fetchJson<T>({ url, timeout = 8000 }: FetchOpts): Promise<FetchResponse<T>> {
  const { publicRuntimeConfig } = getConfig();
  const controller = new AbortController();
  const input = `${publicRuntimeConfig.PUBLIC_HEADLESS_API}${url}`;
  const promise = fetch(input, {
    signal: controller.signal,
  });
  const timeoutId = setTimeout(() => {
    console.warn(`Timed out fetch (${timeout / 1000}s)`, input);
    controller.abort();
  }, timeout);
  return promise
    .then(data =>
      data.json().then(json => ({
        body: json as T,
        headers: data.headers,
        ok: data.ok,
        status: data.status,
      })))
    .finally(() => clearTimeout(timeoutId))
  ;
}

export default fetchJson;
