import type { FallbackProps } from 'react-error-boundary';
import React from 'react';

const ErrorFallback = ({ error }: FallbackProps) => (
  <div role="alert">
    <pre style={{ color: 'red' }}>
      <span aria-label="Boom" role="img">
        💥
      </span>{' '}
      {error?.message}
    </pre>
  </div>
);

export default ErrorFallback;
