import { useContext } from 'react';
import {
  OasisPlayerStoreContext,
} from '@src/components/OasisPlayer/OasisPlayerStore';

const useOasisStickyPlayer = () => {
  const { dispatch } = useContext(OasisPlayerStoreContext);

  return {
    onOasisTrigger: () => dispatch({ type: 'livestream', isInitial: false }),
  };
};

export default useOasisStickyPlayer;
